import React from "react";
import metrics from "../../assets/image/forms/metrics.png";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row mt-10 align-items-center justify-content-center">
          <div
            className="order-lg-1 order-2 col-xl-8 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Responses &amp; Metrics
              </h2>
              <p className="font-size-6 mb-5">
              Export all your responses to a csv file so you can import them to your other seervices.
              </p>
              <p>Metrics will show you how many responses you have had, demographics and what devices have been used to complete your forms.</p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Lead Generation forms</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Sign-up/Registration forms</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Subscription forms</td>
              </tr>                
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Download responses &amp; metrics</td>
              </tr>                                        
            </table>                
              <div className="mt-9">
                <a className="btn bg-form text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="order-lg-2 order-1 col-xl-4 col-lg-6 col-xs-9"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={metrics} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
