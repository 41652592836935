import React from "react";
import drag from "../../assets/image/forms/drag.jpg";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-lg-10 align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={drag} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Drag &amp; drop - No code required
              </h2>
              <p className="font-size-6 mb-0">
                With Digma's intuitive, drag-and-drop form builder, creating forms is a breeze. No coding knowledge is required! Just choose the fields you'd like to add, and drag them over to your form.
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Drag &amp; Drop form elements</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Email notifications</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Webhooks</td>
              </tr>                
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Fully customisable</td>
              </tr>                                        
            </table>              
              <div className="mt-9">
                <a className="btn bg-form text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
