import React from "react";
import embed from "../../assets/image/forms/embed.png";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-10 align-items-center justify-content-center">
          <div
            className="col-xl-3 col-lg-3 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={embed} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-9 col-lg-9 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Embed them anywhere!
              </h2>
              {/* <p className="font-size-6 mb-0">
                Just copy and paste one line of embed code and ...
              </p>           */}
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Interactive videos</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Interactive image</td>
              </tr>  
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Websites</td>
              </tr>                            
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Blogs</td>
              </tr>                                        
            </table>                     
              <div className="mt-9">
                <a className="btn bg-form text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
